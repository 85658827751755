import React from 'react'
import "./Store.css";
class Store extends React.Component {
    // state = {  }

    // componentDidMount() {
    //     var client = ShopifyBuy.buildClient({
    //         domain: 'subtleorange.myshopify.com',
    //         storefrontAccessToken: '24113dd2db4c23c1c4518123e7f40d40', // previously apiKey, now deprecated
    //     });

    //     var ui = ShopifyBuy.UI.init(client);

    //     ui.createComponent('collection', {
    //         id: 235486675101,
    //         node: document.getElementById('my-collection')
    //       });

    // }

    // render() { 
    //     return ( 
    //         <div id="my-collection"></div>
    //      );
    // }


    render() {
        return (<div className="form-container">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeIY4mDICcs6JexXe2ebWo1ZdnE-54LsNXFkiIDOqjeHVCZFg/viewform?embedded=true" className="google-form" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>);
    }
}
 
export default Store;