import React from "react";
import "./Home.css";
import { Carousel, Container, Row, Col } from "react-bootstrap";
import lacysplace from '../images/lacys-place.JPG'

class Home extends React.Component {
  state = {};
  render() {
    return (
      <div className="home-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/3WZum7h-OLw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
        <h2 className="subheader">lacy's place</h2>
        <p>our third EP out June 25th
        </p>
        <Container className="project-grid">
                    <Row>
                        <Col>
                            <a href="https://open.spotify.com/artist/60LsU7GzzmpYrDCLOUZOv4?si=hzOEanA9TDaXT55xKRhsMQ&dl_branch=1">
                                <img className="album-icon anchor-album welcome-album" src={lacysplace} alt="" />
                                {/* <h5 className="album-detail">Stream It</h5> */}
                            </a>
                        </Col>
                    </Row>         
         </Container>
      </div>
    );
  }
}

export default Home;
