import React from 'react'
import './Music.css'
import spotify from '../images/spotify.png'
import youtube from '../images/youtube.png'
import soundcloud from '../images/soundcloud.svg'
import apple from '../images/apple.png'
import bandcamp from '../images/bandcamp.png'
import albionCover from '../images/albion-cover.png'
import soCover from '../images/so-cover.png'
import fpCover from '../images/fp-cover.jpg'
import ducksCover from '../images/ducks-cover.jpg'

import { Container, Row, Col } from 'react-bootstrap'

class Music extends React.Component {
    state = {  }
    render() { 
        return ( 
            <div>
                <h2 className="subheader" >Links</h2>
                <div>
                    <a href="https://open.spotify.com/artist/60LsU7GzzmpYrDCLOUZOv4?si=7_0NZ25PR3OLZmr6R4RVqg"><img className="music-icon" src={spotify} alt="" /></a>
                    <a href="https://www.youtube.com/channel/UCIjqjsg9XhQ6fscV0X7mOxw"><img className="music-icon" src={youtube} alt="" /></a>
                    <a href="https://music.apple.com/us/artist/subtle-orange/1276389179"><img className="music-icon" src={apple} alt="" /></a>
                    <a href="https://soundcloud.com/subtle-orange"><img className="music-icon" src={soundcloud} alt="" /></a>
                    <a href="https://subtleorange.bandcamp.com"><img className="music-icon" src={bandcamp} alt="" /></a>
                </div>
                <h2 className="subheader" >Projects</h2>
                <Container className="project-grid">
                    <Row>
                        <Col>
                            <a href="https://open.spotify.com/album/5nIMnLEZifVa713DL1qAUD?si=3gmK2oh8TxyWw_w8wziv6w">
                                <img className="album-icon" src={ducksCover} alt="" />
                                <h5 className="album-detail">Sitting Ducks</h5>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://open.spotify.com/album/5k4cu3RlzcLstZPuiMvdVu?si=7sKzqTgLSEuhTE49WysBXw">
                                <img className="album-icon" src={fpCover} alt="" />
                                <h5 className="album-detail">Fruit Punch</h5>
                            </a>
                        </Col>
                        
                        
       
                    </Row>
                    <Row>
                        <Col>
                            <a href="https://open.spotify.com/album/4puFQFhzJeoHlJ3CBukRsn?si=YhnNg2ahSjCvjyQi074O6Q">
                                <img className="album-icon" src={albionCover} alt="" />
                                <h5 className="album-detail">Albion</h5>
                            </a>
                        </Col>
                        <Col>
                            <a href="https://open.spotify.com/album/6jNqWQgH4jm6TUUyuYwQaW?si=w30eFd2xTG6HeYfvMp9DcA">
                                <img className="album-icon" src={soCover} alt="" />
                                <h5 className="album-detail">Subtle Orange</h5>
                            </a>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
         );
    }
}
 
export default Music;