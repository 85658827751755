import React from 'react'
import './Press.css'
import { Container, Row, Col } from 'react-bootstrap'
import scratch from '../images/scratch.png'
import otc from '../images/otc.jpg'

class Press extends React.Component {
    state = {  }
    render() { 
        return ( 
            <div>
                <h2 className="subheader">Subtle Orange in the Press</h2>
                <Container className="press-grid">
                    <Row className="press-row">
                        <Col className="image-col">
                            <a className="press-anchor" href="https://www.scratch-zine.com/product/scratch-magazine-vol-3">
                                <img className="press-image" src={scratch} alt="" />
                            </a>
                        </Col>
                        <Col className="text-col">
                           <a className="press-anchor" href="https://www.scratch-zine.com/product/scratch-magazine-vol-3">
                                <span className="press-headline">Scratch Zine</span> <br/>
                                <span className="press-date"></span>March 2021<br/>
                                <span className="press-detail">Vol. 3</span>
                            </a>
                        </Col>   
                    </Row>
                    <Row className="press-row">
                        <Col className="image-col">
                            <a className="press-anchor" href="https://www.offthecassette.com/blog/artist-spotlight/subtle-orange">
                                <img className="press-image" src={otc} alt="" />
                            </a>
                        </Col>
                        <Col className="text-col">
                           <a className="press-anchor" href="https://www.offthecassette.com/blog/artist-spotlight/subtle-orange">
                                <span className="press-headline">Off The Cassette</span> <br/>
                                <span className="press-date"></span>March 2021<br/>
                                <span className="press-detail">Artist Spotlight: Subtle Orange</span>
                            </a>
                        </Col>   
                    </Row>
                </Container>

            </div>
         );
    }
}
 
export default Press;