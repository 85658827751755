import React from 'react'
import './Gallery.css'
import Carousel from 'nuka-carousel'


class ImageGallery extends React.Component {
    state = {  }
    render() { 
        return ( 
            <Carousel className="carousel" autoplay width="50vw" height="80vh">
                <img className="carousel-image" src={require('../gallery/1.JPG')} />
                <img className="carousel-image" src={require('../gallery/2.JPG')} />
                <img className="carousel-image" src={require('../gallery/3.JPG')} />
                <img className="carousel-image" src={require('../gallery/4.JPG')} />
                <img className="carousel-image" src={require('../gallery/5.JPG')} />
                <img className="carousel-image" src={require('../gallery/6.JPG')} />
                <img className="carousel-image" src={require('../gallery/7.JPG')} />
                <img className="carousel-image" src={require('../gallery/8.JPG')} />
                <img className="carousel-image" src={require('../gallery/9.JPG')} />
                <img className="carousel-image" src={require('../gallery/10.JPG')} />
                <img className="carousel-image" src={require('../gallery/11.JPG')} />
                <img className="carousel-image" src={require('../gallery/12.JPG')} />
                <img className="carousel-image" src={require('../gallery/13.JPG')} />
                <img className="carousel-image" src={require('../gallery/14.JPG')} />
                <img className="carousel-image" src={require('../gallery/15.JPG')} />
                <img className="carousel-image" src={require('../gallery/16.JPG')} />
                <img className="carousel-image" src={require('../gallery/17.JPG')} />
                <img className="carousel-image" src={require('../gallery/18.JPG')} />
                <img className="carousel-image" src={require('../gallery/19.JPG')} />
                <img className="carousel-image" src={require('../gallery/20.JPG')} />
                <img className="carousel-image" src={require('../gallery/21.JPG')} />
                <img className="carousel-image" src={require('../gallery/22.JPG')} />
                <img className="carousel-image" src={require('../gallery/23.JPG')} />
                <img className="carousel-image" src={require('../gallery/24.JPG')} />
                <img className="carousel-image" src={require('../gallery/25.JPG')} />
                <img className="carousel-image" src={require('../gallery/26.JPG')} />
                <img className="carousel-image" src={require('../gallery/27.JPG')} />
                <img className="carousel-image" src={require('../gallery/28.JPG')} />
                <img className="carousel-image" src={require('../gallery/29.JPG')} />
                <img className="carousel-image" src={require('../gallery/30.JPG')} />
                <img className="carousel-image" src={require('../gallery/31.JPG')} />
                <img className="carousel-image" src={require('../gallery/32.JPG')} />
                <img className="carousel-image" src={require('../gallery/33.JPG')} />
                <img className="carousel-image" src={require('../gallery/34.JPG')} />
                <img className="carousel-image" src={require('../gallery/35.JPG')} />
                <img className="carousel-image" src={require('../gallery/36.JPG')} />
                <img className="carousel-image" src={require('../gallery/37.JPG')} />
                <img className="carousel-image" src={require('../gallery/38.JPG')} />
                <img className="carousel-image" src={require('../gallery/39.JPG')} />
                <img className="carousel-image" src={require('../gallery/40.JPG')} />
                <img className="carousel-image" src={require('../gallery/41.JPG')} />
                

                
            </Carousel>
         );
    }
}
 
export default ImageGallery;